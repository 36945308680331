import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import BackButton from '@shared/ui/navigation/BackButton';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import { Title } from '@src/components/styled/Typography';
import PortfolioListFilters from './ListFilters';
const ListContainer = styled.div();
const ControlsContainer = styled.div((props) => ({
    display: 'flex',
    justifyContent: 'center',
    borderBottom: `1px solid ${props.theme.customColors.input.border}`,
}));
const ControlsInnerContainer = styled.div((props) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    gap: `${props.theme.spacing_sizes.l}px 0`,
    padding: `${props.theme.spacing_sizes.l}px 0`,
    width: '80%',
}));
const BackLinkContainer = styled.div();
const ResultsContainer = styled.div();
const PortfolioList = () => {
    const { t } = useTranslation();
    const { navigate, routes } = useAppNavigation();
    const goToMarketplace = () => {
        navigate(routes.MARKETPLACE);
    };
    return (_jsxs(ListContainer, { children: [_jsx(ControlsContainer, { children: _jsxs(ControlsInnerContainer, { children: [_jsx(BackLinkContainer, { children: _jsx(BackButton, { text: t('marketplace.all_portfolio_view.backlink_text', { ns: 'common' }), onClick: goToMarketplace }) }), _jsx(Title, { children: Trans({
                                i18nKey: 'marketplace.all_portfolio_view.title',
                                ns: 'common',
                                values: { portfoliosCount: 0 },
                            }) }), _jsx(PortfolioListFilters, {})] }) }), _jsx(ResultsContainer, {})] }));
};
export default PortfolioList;
