import { useState } from 'react';
import { ExchangeFilter, PeriodFiler, SortFilter } from './constants';
const INIT_FILTERS = {
    searchString: '',
    period: PeriodFiler['30_DAYS'],
    exchange: ExchangeFilter.All,
    sort: SortFilter.Profit,
    activeView: 'card',
    socials: [],
    subscriptionPrice: [0, 1500],
    subscriptionPriceMax: [0, 1500],
    maxDropdown: 25,
    areAdditionalFiltersOpen: false,
};
const useListFilters = () => {
    const [listFilters, setListFilters] = useState(INIT_FILTERS);
    const handleSearchStringChange = (searchString) => {
        setListFilters((prev) => (Object.assign(Object.assign({}, prev), { searchString })));
    };
    const toggleVisibility = () => {
        setListFilters((prev) => (Object.assign(Object.assign({}, prev), { areAdditionalFiltersOpen: !prev.areAdditionalFiltersOpen })));
    };
    const updateFilter = (key, value) => {
        setListFilters((prev) => (Object.assign(Object.assign({}, prev), { [key]: value })));
    };
    const handleViewTypeChange = (viewType) => setListFilters((prev) => (Object.assign(Object.assign({}, prev), { activeView: viewType })));
    const updateSocials = (activeKeys) => setListFilters((prev) => (Object.assign(Object.assign({}, prev), { socials: activeKeys })));
    const updateSubscriptionPrice = (value) => {
        setListFilters((prev) => (Object.assign(Object.assign({}, prev), { subscriptionPrice: value })));
    };
    const updateSubscriptionPriceInput = (value, position) => {
        setListFilters((prev) => {
            if (position === 'start') {
                return Object.assign(Object.assign({}, prev), { subscriptionPrice: [value, prev.subscriptionPrice[1]] });
            }
            return Object.assign(Object.assign({}, prev), { subscriptionPrice: [prev.subscriptionPrice[0], value] });
        });
    };
    const handleDropdownChange = (value) => {
        setListFilters((prev) => (Object.assign(Object.assign({}, prev), { maxDropdown: value })));
    };
    return Object.assign(Object.assign({}, listFilters), { toggleVisibility,
        updateFilter,
        handleSearchStringChange,
        handleViewTypeChange,
        updateSocials,
        updateSubscriptionPrice,
        updateSubscriptionPriceInput,
        handleDropdownChange });
};
export default useListFilters;
