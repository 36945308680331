import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import useMarketplaceInfo from '../../shared/lib/hooks/useMarketplaceInfo';
import PortfolioList from './PortfolioList';
const MarketplaceList = () => {
    const { quotations, portfolioList } = useMarketplaceInfo();
    const { t } = useTranslation();
    return (_jsx(PortfolioList, {}));
};
export default MarketplaceList;
